import { useCallback, useEffect, useState } from "react";
import { BottomNavigation } from "../../components/BottomNavigation"
import { useProjectCode } from "../../hooks/useProjectCode"
import { ICargo, ICharacter, IProfile, ISubMember, IUserCharacter } from "../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../api";
import { MarketCard } from "./MarketCard";
import { BottomNavigationSpace } from "../../components/BottomNavigationSpace";
import { Button, Center, CloseButton, Divider, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, ListItem, Spacer, Spinner, UnorderedList, useDisclosure, useToast } from "@chakra-ui/react";
import { MarketPhotoGrid } from "./MarketPhotoGrid";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../stores/profile";
import { TbMapPinSearch } from 'react-icons/tb';
import { IGeolocation } from '../../interfaces/IGeolocation';
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { Utils } from "../../utils";
import { INearby } from "../../interfaces/INearby";
import { BottomDrawer } from "./BottomDrawer";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ContentBlockWithHint } from "../../components/ContentBlockWithHint";
import { FeedbackDialog } from "../../components/FeedbackDialog";
import { ISearchKeyword } from "../../interfaces/ISearchKeyword";
import { LuArrowRightCircle } from "react-icons/lu";

export const MarketPage = () => {
  const projectCode = useProjectCode();
  const { masterMemberNo } = useParams();
  const { t } = useTranslation();
  const api = useApi(projectCode);
  const toast = useToast();
  const profile = useRecoilValue(profileAtom);
  const charactersApi = PublicCrudApi('sns', 'characters/users')
  const params = new URLSearchParams();
  const profileApi = PublicCrudApi('core', `profiles/${projectCode}/multi-gender`)
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUserCharacter[]>([])
  const [fsProfiles, setFsProfiles] = useState<IProfile[]>([])
  const [fmProfiles, setFmProfiles] = useState<IProfile[]>([])
  const [msProfiles, setMsProfiles] = useState<IProfile[]>([])
  const [mmProfiles, setMmProfiles] = useState<IProfile[]>([])
  const [subMembers, setSubMembers] = useState<ISubMember[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [searchProfiles, setSearchProfiles] = useState<IProfile[]>([]);
  const geolocationApi = PublicCrudApi('sns', 'geolocation');
  const geolocationsApi = PrivateCrudApi('sns', 'geolocations');
  const [geolocation, setGeolocation] = useState<IGeolocation>();
  const [nearbyData, setNearByData] = useState<INearby[]>([]);
  const [nearbyProfiles, setNearbyProfiles] = useState<IProfile[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [characters, setCharacters] = useState<ICharacter[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerTitle, setDrawerTitle] = useState<String>('');
  const [characterUsers, setCharacterUsers] = useState<IUserCharacter[]>([]);
  const popSearchApi = PublicCrudApi('sns', 'search/popular-keyword');
  const [searchKeywords, setSearchKeywords] = useState<ISearchKeyword[]>([]);
  const [isSearching, setSearching] = useState<boolean>(false);

  const searchProfile = (k?: string) => {
    setSearching(true);
    setSearchProfiles([]);
    api.searchProfile(k || keyword, (cargo: ICargo) => {
      setSearching(false);
      if (cargo.success) {
        setSearchProfiles(cargo.info);
      }
    });
  }

  useEffect(() => {
    if (!Utils().isLogin()) {
      navigate(`/${projectCode}/login`)
    }
    api.getCharacters((cargo: ICargo) => {
      if (cargo.success) {
        setCharacters(cargo.info);
      }
    });
    popSearchApi.getById(projectCode).then(data => setSearchKeywords(data as ISearchKeyword[]));
  }, []);

  const fetchSubMembers = useCallback(() => {
    if (!masterMemberNo) return;
    api.getSubMembers(masterMemberNo, (cargo: ICargo) => {
      setSubMembers(cargo.info['subNodes']);
    });
  }, [api])

  useEffect(() => {
    if (!profile) return;
    fetchSubMembers();
    if (profile?.simpleCode) {
      geolocationApi
        .getById(profile?.simpleCode)
        .then((data) => setGeolocation(data as IGeolocation))
        .catch(e => console.log(e));
    }

    // charactersApi.getById(72).then(data => setUsers(data as IUserCharacter[]))
    let lookFor = profile.lookFor;
    if (lookFor === 0 || lookFor === 5 || lookFor === 6) {
      params.append('pageSize', '50');
      profileApi
        .getById(2, params)
        .then((data) => setFsProfiles(data as IProfile[]));
      profileApi
        .getById(4, params)
        .then((data) => setFmProfiles(data as IProfile[]));
      params.delete('pageSize');
      params.append('pageSize', '30');
      profileApi
        .getById(1, params)
        .then((data) => setMsProfiles(data as IProfile[]));
      profileApi
        .getById(3, params)
        .then((data) => setMmProfiles(data as IProfile[]));
    } else {
      const maxSize = '100';
      const minSize = '20';
      profileApi
        .getById(
          2,
          new URLSearchParams({
            pageSize: lookFor === 2 ? maxSize : minSize,
          })
        )
        .then((data) => setFsProfiles(data as IProfile[]));
      profileApi
        .getById(
          4,
          new URLSearchParams({
            pageSize: lookFor === 4 ? maxSize : minSize,
          })
        )
        .then((data) => setFmProfiles(data as IProfile[]));
      profileApi
        .getById(
          1,
          new URLSearchParams({
            pageSize: lookFor === 1 ? maxSize : minSize,
          })
        )
        .then((data) => setMsProfiles(data as IProfile[]));
      profileApi
        .getById(
          3,
          new URLSearchParams({
            pageSize: lookFor === 3 ? maxSize : minSize,
          })
        )
        .then((data) => setMmProfiles(data as IProfile[]));
    }
    if (!profile.qualify) {
      toast({
        title: 'Bad quality avatar',
        description: '您的頭貼不合格將無法觀看他人頭貼',
        status: 'error',
        position: 'top',
        duration: 90000,
        isClosable: true,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (!geolocation) return;
    if (!geolocation?.geohash) return;
    api.getNearbyProfiles(
      projectCode,
      geolocation?.geohash,
      (cargo: ICargo) => {
        const nearbyData: INearby[] = cargo.info;
        setNearByData(nearbyData);
        setNearbyProfiles(
          nearbyData
            .sort((a, b) => {
              if (!geolocation.latitude || !geolocation.longitude) return 0;
              return (
                Utils().calculateDistance(
                  geolocation.latitude,
                  geolocation.longitude,
                  a.latitude,
                  a.longitude
                ) -
                Utils().calculateDistance(
                  geolocation.latitude,
                  geolocation.longitude,
                  b.latitude,
                  b.longitude
                )
              );
            })
            .map((n) => n.profile)
            .filter((p) => p.simpleCode !== profile?.simpleCode)
        );
      }
    );
  }, [geolocation]);
  
  return (
    <>
      <div className="mb-3">
        <label
          htmlFor="profile-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="profile-search"
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Nickname"
            required
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-yellow-500 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition-all"
            onClick={() => {
              searchProfile();
            }}
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex">
        <span className="whitespace-nowrap text-xs py-1 pl-1">熱搜</span>
        <Divider orientation="vertical" className="pr-3 divide-gray-600" />
        <div className="flex overflow-x-scroll no-scrollbar whitespace-nowrap gap-1 mb-4">
          {searchKeywords.map((searchKeyword) => {
            return (
              <span
                className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
                key={searchKeyword.keyword}
                onClick={() => {
                  setKeyword(searchKeyword.keyword);
                  searchProfile(searchKeyword.keyword);
                }}
              >
                {searchKeyword.keyword}
              </span>
            );
          })}
        </div>
      </div>
      <div className="border rounded-lg px-3 pb-3 pt-3 mb-3 shadow">
        <Center
          className="cursor-pointer"
          onClick={() => {
            setOpenDrawer(true);

            if ('geolocation' in navigator) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const geo = Utils().geolocationBuilder(
                    `${position.coords.latitude},${position.coords.longitude}`
                  );
                  setGeolocation(geo);
                  if (projectCode) {
                    geolocationsApi.update(projectCode.toUpperCase(), geo);
                  }
                },
                (error) => {
                  console.error('Error getting location:', error.message);
                  toast({
                    title: 'Error getting location',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                  setOpenDrawer(false);
                }
              );
            } else {
              console.error('Geolocation is not supported by your browser');
              toast({
                title: 'Error',
                description: 'Geolocation is not supported by your browser',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
          }}
        >
          <TbMapPinSearch size={30} color="gray" />
          <div className="ml-3 text-gray-500 select-none">
            {t('whoIsNearby')}
          </div>
        </Center>
      </div>
      {users.length > 0 && <MarketPhotoGrid title="偽娘" users={users} />}
      {(searchProfiles.length > 0 || isSearching) && (
        <MarketPhotoGrid
          title="Search"
          profiles={searchProfiles}
          badgeClass="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300"
          isLoading={isSearching}
          moreBtn={false}
          qualifyOnly={false}
        />
      )}
      {/* {nearbyProfiles.length > 0 && (
        <MarketPhotoGrid
          title="Near By"
          profiles={nearbyProfiles}
          badgeClass="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300"
        />
      )} */}
      {fsProfiles.length > 0 && (
        <MarketPhotoGrid
          title="S"
          profiles={fsProfiles}
          badgeClass="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300"
        />
      )}
      {fmProfiles.length > 0 && (
        <MarketPhotoGrid
          title="M"
          profiles={fmProfiles}
          badgeClass="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-pink-900 dark:text-pink-300"
        />
      )}
      {msProfiles.length > 0 && (
        <MarketPhotoGrid
          title="S"
          profiles={msProfiles}
          badgeClass="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300"
        />
      )}
      {mmProfiles.length > 0 && (
        <MarketPhotoGrid
          title="M"
          profiles={mmProfiles}
          badgeClass="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
        />
      )}
      <div className="flex overflow-x-scroll no-scrollbar whitespace-nowrap gap-1 mb-4">
        {characters
          .sort((a, b) => (b.hit || 0) - (a.hit || 0))
          .map((character) => {
            return (
              <span
                className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
                key={character.idCharacter}
                onClick={() => {
                  setCharacterUsers([]);
                  charactersApi.getById(character.idCharacter).then((data) => {
                    setCharacterUsers(data as IUserCharacter[]);
                  });
                  setDrawerTitle(character.title);
                  onOpen();
                }}
              >
                {character.title}
              </span>
            );
          })}
      </div>
      {/* <ContentBlockWithHint title={'NEWS'} firstLineHint={'歡迎至許願池留言'}>
        <div className="text-gray-500">
          市集近期正規劃提供實體商品銷售服務
          <br />
          歡迎留下訊息~
          <br />
          我們將優先邀請您加入市集
        </div>
      </ContentBlockWithHint> */}
      <ContentBlockWithHint title="喬遷之喜" firstLineHint="虐戀者俱樂部">
        <div className="text-center p-3">
          <div className="flex items-center gap-3 justify-center">
            <div>BDSMTW.CLUB</div>
            <LuArrowRightCircle />
            <div>BDSMER.CLUB</div>
          </div>
          <div
            className="underline cursor-pointer"
            onClick={() => {
              window.location.href = window.location.href.replace(
                'bdsmtw',
                'bdsmer'
              );
            }}
          >
            https://bdsmer.club
          </div>
        </div>
      </ContentBlockWithHint>
      <div className="mb-3">
        <FeedbackDialog />
      </div>
      <div className="pt-3 mb-5">
        <div className="p-3 mb-5 text-sm">
          <UnorderedList>
            <ListItem>主人可獲得70%成交價</ListItem>
            <ListItem>30%為交易手續費由平台銷毀</ListItem>
            <ListItem>每次競價為 1 MC，不退回</ListItem>
            <ListItem>最後一次投標後，12小時若無其它人競價則為得標人</ListItem>
          </UnorderedList>
        </div>
        <Button
          colorScheme={'white'}
          shadow="lg"
          color={'green.900'}
          width="100%"
          onClick={() => {
            navigate(`/${projectCode.toLowerCase()}/s?c=${masterMemberNo}`);
          }}
        >
          上架出售
        </Button>
      </div>
      {subMembers.filter(
        (member) => !member.profile.blur && member.profile.avator
      ).length === 0 && <div>目前市場沒有寵物可供標售</div>}
      <div className="gap-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10">
        {subMembers
          .filter((member) => !member.profile.blur && member.profile.avator)
          .map((subMember) => {
            return (
              <div
                key={subMember.profile.idProfile}
                className="mb-5 inline-block w-full"
              >
                <MarketCard
                  profile={subMember.profile}
                  onPurchased={() => {
                    navigate(`/${projectCode}/profile`);
                  }}
                  onBidding={fetchSubMembers}
                />
              </div>
            );
          })}
      </div>
      <BottomDrawer
        nearbyList={nearbyData}
        geolocation={geolocation}
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
      <BottomNavigationSpace />
      <BottomNavigation projectCode={projectCode} />
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              {drawerTitle}
              <Spacer></Spacer>
              <CloseButton onClick={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {characterUsers.length > 0 ? (
              <div>
                {characterUsers
                  .sort((a, b) => {
                    return moment(a.dateUpd).isBefore(b.dateUpd) ? 1 : -1;
                  })
                  .filter((d) => d.avator && d.nickname)
                  .map((characterUser) => {
                    return (
                      <div
                        key={characterUser.simpleCode}
                        className="flex px-4 py-3 cursor-pointer hover:bg-slate-100 rounded-lg"
                        onClick={() => {
                          navigate(
                            `/${projectCode}/share/${characterUser.simpleCode}`
                          );
                        }}
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-lg w-11 h-11"
                            src={
                              characterUser.avator === null
                                ? `https://bdsmtw.club/files/${projectCode}/logo.jpg`
                                : Utils().normalizeImagePath(
                                    characterUser.avator,
                                    projectCode
                                  )
                            }
                            alt={characterUser.nickname}
                          />
                        </div>
                        <div className="w-full ps-3">
                          <div className="text-gray-500 text-sm mb-1.5">
                            {characterUser.nickname}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Center>
            )}
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" onClick={onClose}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}