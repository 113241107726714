import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Translation } from 'react-i18next';
import {
  Center,
  Textarea,
  Button, 
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Slide,
} from '@chakra-ui/react'
import { useApi } from '../api'
import { ICargo } from '../interfaces';

export const FeedbackDialog = () => {
  const { projectCode } = useParams()
  const api = useApi(projectCode)

  const OverlayConfig = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayConfig />)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const submit = () => {
    if (message.trim().length === 0) {
      return
    }
    setIsLoading(true)
    api.postFeedback(message, (cargo: ICargo) => {
      setIsLoading(false)
      if (cargo.returnCode === '000000') {
        onClose()
        setShowSuccessAlert(true)
        setTimeout(() => {
          setShowSuccessAlert(false)
        }, 5000)
        setMessage('')
      }
    })
  }

  return (
    <>
      <div onClick={() => {
        setOverlay(<OverlayConfig />)
        onOpen()
      }}>
        <Button
          className='w-full'
          colorScheme={'white'}
          shadow="lg"
          color={'green.900'}
        >告解室/許願池</Button>
      </div>
      <Modal size={"xs"} isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>
            告解室
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6}>
            <Center>
              <Textarea
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                value={message}
                rows={10}
                placeholder='放心的說出心理話吧'
              />
            </Center>
            <div className='text-sm mt-3'>
              告解(Confession)：
            </div>
            <div className='text-xs'>
              告白(Confessio)與獲得解脫(Absolutio)的融合
            </div>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} onClick={submit}>
            告解
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Slide direction='bottom' in={showSuccessAlert} style={{ zIndex: 10 }}>
        <Alert
          colorScheme='whiteAlpha'
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Feedback submitted!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Thanks for submitting your suggestion. Our team will get back to you soon.
          </AlertDescription>
        </Alert>
      </Slide>
    </>
  )
}