import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormControl,
  FormLabel,
  Switch,
  Button,
} from '@chakra-ui/react';
import { useApi } from '../../api'
import { ICargo, IChatMessage, IProfile } from "../../interfaces";
import { BottomNavigation } from '../../components/BottomNavigation'
import { t } from 'i18next'
import { usePrivateApi } from '../../hooks/usePrivateApi';
import { LastChatroomMessageBlock } from '../../components/LastChatroomMessageBlock';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../../stores/profile';
import { NotificationCard } from './NotificationCard';
import { PrivateCrudApi } from '../../apis/private-crud-api';

export const NotificationPage = () => {
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const { projectCode } = useParams()
  const api = useApi(projectCode)
  const privateApi = usePrivateApi();
  const [chatroomItems, setChatroomItems] = useState<[]>();
  const [messageItems, setMessageItems] = useState<[]>()
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [replyProfile, setReplyProfile] = useState<IProfile|null>(null)
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [confirmDel, setConfirmDel] = useState<boolean>(false)
  const [unreadChatroomMessageCount, setUnreadChatroomMessageCount] = useState<number>(0);
  const [chatroomNewIndicator, setChatroomNewIndicator] = useState<boolean>(false);
  const [personNewIndicator, setPersonalNewIndicator] = useState<boolean>(false);
  const profileApi = PrivateCrudApi('core', 'profiles');

  const fetchChatroomMessages = useCallback(() => {
    privateApi.getChatroomsMessages((cargo: ICargo) => {
      const profileSet = new Set();
      const chatroomProfileSet = new Set();
      const localMemberNo = window.localStorage.getItem('memberNo');

      const chatroomMsgs = cargo.info
        .filter(
          (chatroomMessage: IChatMessage) =>
            chatroomMessage.chatroomId === chatroomId
        )
        .map((chatroomMessage: IChatMessage) => {
          if (!localMemberNo || !chatroomMessage.profile?.simpleCode)
            return null;
          if (chatroomProfileSet.has(chatroomMessage.profile?.idProfile))
            return null;
          chatroomProfileSet.add(chatroomMessage.profile?.idProfile);
          if (!chatroomMessage.read) {
            setChatroomNewIndicator(true);
          }
          return (
            <NotificationCard
              key={`chm_${chatroomMessage.idChatroomMessage}`}
              chatroomMessage={chatroomMessage}
              localMemberNo={localMemberNo}
              replyProfile={replyProfile}
              onChatroomMessageDeleted={fetchChatroomMessages}
              goChatroomUrl={`/${projectCode}/public-chat/${chatroomId}`}
            />
          );
        });
      setChatroomItems(chatroomMsgs);

      const personalMsgs = cargo.info
        .filter(
          (chatroomMessage: IChatMessage) =>
            chatroomMessage.chatroomId !== chatroomId
        )
        .map((chatroomMessage: IChatMessage) => {
          if (!localMemberNo || !chatroomMessage.profile?.simpleCode)
            return null;
          if (profileSet.has(chatroomMessage.profile?.idProfile)) return null;
          profileSet.add(chatroomMessage.profile?.idProfile);
          if (!chatroomMessage.read) {
            setPersonalNewIndicator(true);
          }
          return (
            <NotificationCard
              key={`chm_${chatroomMessage.idChatroomMessage}`}
              chatroomMessage={chatroomMessage}
              localMemberNo={localMemberNo}
              replyProfile={replyProfile}
              onChatroomMessageDeleted={fetchChatroomMessages}
            />
          );
        });
      setMessageItems(personalMsgs);
    })
  }, [privateApi])

  useEffect(() => {
    api.getProfile((cargo: ICargo) => {
      setProfile(cargo.info)
    })
    fetchChatroomMessages();
    setTabIndex(
      window.localStorage.getItem('lastMessagePageTab') === 'message' ? 1 : 0
    );
    privateApi.getUnreadChatroomMessageCount((cargo:ICargo) => {
      if (cargo.success) {
        setUnreadChatroomMessageCount(cargo.info);
      }
    })
    profileApi.partialUpdate(`${localStorage.getItem('memberId')}`, {
      language: window.navigator.language
    });
  }, [])

  return (
    <>
      <LastChatroomMessageBlock />
      <div className="p-3">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0" className="p-1">
            {t('enableEmailAlert')}
          </FormLabel>
          <Spacer />
          {profile && (
            <Switch
              id="email-alerts"
              size={'lg'}
              colorScheme={'yellow'}
              isDisabled={!profile}
              isChecked={profile && !profile.muted}
              onChange={(e) => {
                api.updateMuted(!e.target.checked);
                setProfile({ ...profile, muted: !e.target.checked });
                if (e.target.checked) {
                  if (window.Notification) {
                    Notification.requestPermission();
                  }
                  if (!('serviceWorker' in navigator)) {
                    // Service Worker isn't supported on this browser, disable or hide UI.
                    return;
                  }

                  if (!('PushManager' in window)) {
                    // Push isn't supported on this browser, disable or hide UI.
                    return;
                  }
                }
              }}
            />
          )}
        </FormControl>
      </div>
      <Tabs
        isFitted
        variant="enclosed"
        className="shadow rounded-b-xl mb-5"
        index={tabIndex}
      >
        <TabList mb="1em">
          <Tab
            onClick={() => {
              setTabIndex(0);
              window.localStorage.setItem('lastMessagePageTab', 'guestbook');
            }}
          >
            <div className="relative w-full">
              <span>{t('chatroomNoti')}</span>
              {chatroomNewIndicator && (
                <span className="absolute right-0 mr-[-13px] mt-[-5px] bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                  New
                </span>
              )}
            </div>
          </Tab>
          <Tab
            onClick={() => {
              setTabIndex(1);
              fetchChatroomMessages();
              window.localStorage.setItem('lastMessagePageTab', 'message');
            }}
            className="relative"
          >
            <div className="relative w-full">
              <span>{t('personalNoti')}</span>
              {personNewIndicator && (
                <span className="absolute right-0 mr-[-13px] mt-[-5px] bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                  New
                </span>
              )}
            </div>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Button
              colorScheme={confirmDel ? 'red' : 'white'}
              shadow="lg"
              color={confirmDel ? 'white' : 'green.900'}
              width="100%"
              onClick={() => {
                if (!confirmDel) {
                  setConfirmDel(true);
                } else {
                  setConfirmDel(false);
                  privateApi.deleteChatroomMessagesByChatroomId(
                    chatroomId,
                    () => {
                      fetchChatroomMessages();
                    }
                  );
                }
              }}
            >
              {confirmDel ? '再按一次清除' : t('emptyChatroomNoti')}
            </Button>
            {chatroomItems}
          </TabPanel>
          <TabPanel>{messageItems}</TabPanel>
        </TabPanels>
      </Tabs>
      <div className="h-[130px]"></div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
};